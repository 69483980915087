<template>
    <div class="hello">

   <!-- Header start -->
    <header class="header">
        <div class="header__container flex-space">
            <a href="/" >
                <h1>vim.is</h1>
            </a>
          
          
        </div>
    </header>
    <!-- Header end -->

	<div class="basic-1 cards-1">
	<div class="container">
		<div class="row">


        <div class="col-lg-12">
            <div class="above-heading">Exercise {{index}}/{{this.data.length}} </div>
            <h2 class="">{{data[index].title}}</h2>
        </div>
    
        <div class="col-lg-12">
        <div v-html="data[index].question">> {{data[index].question}}
            <br></div>
        <br>
        </div>
    
        <div id="parent" v-on:click="start()">
            <VueCodeEditor @init="editorInit" theme="monokai" width="100%" height="200px" id="editor" name="editor" v-on:input="onChange" v-model=answer ref="editor" :options="{
                fontSize: 18,
                highlightActiveLine: true,
                showLineNumbers: true,
                tabSize: 2,
            }" />>
        </div>
       
        <div class="col-lg-12">
            <!-- 
              <span class="btn-solid-reg popup-with-move-anim" href="#details-lightbox-1" v-on:click="back()">Last exercise</span>
              <span class="btn-solid-reg popup-with-move-anim" href="#details-lightbox-1" v-on:click="next()">Next exercise</span>
            -->

            <div class="hero-cta">
                <a href="#exercise" class="btn btn__primary" v-on:click="restart()">
                    RESTART
                </a>

                <a href="#exercise" class="btn btn__default" v-on:click="reset()">
                    RESET
                </a>
                <div style="margin-right:1rem;"></div>  
                <a href="../overview" class="btn btn__default">
                    OVERVIEW
                </a>
            </div>

            <br /><a href="../overview">Lesson Plan</a>


            <!-- {{this.answer}} -->
        </div>
    </div>

      </div>
  </div>

  </div>
</template>

<script>
import router from '../router'

window.ace.require = window.ace.acequire;
// import { Ace as AceEditor } from 'vue2-brace-editor';
import VueCodeEditor from 'vue2-code-editor';

export default {
    components: {
        VueCodeEditor
    },
    name: 'HelloWorld',
    props: {
        msg: String
    },
    computed: {
        formattedElapsedTime() {
            const date = new Date(null);
            //date.setSeconds(this.elapsedTime / 1000);
            date.setMilliseconds(this.elapsedTime);
            const utc = date.toISOString();
            return utc.substr(utc.indexOf(".") - 2, 5) + 's';
        },
        id() {
            return this.$route.params.id;
        },
    },
    created: function() {

        var key = this.$cookie.get('key');
        if (key === null) 
            router.replace("/login");


        this.getData();

    },
    methods: {
        getId: function() {
            return this.$route.params.id;
        },
        start: function() {
            this.startTimer();
        },
        editorInit: function(editor) {
            require('brace/mode/html')
            require('brace/mode/python')
            require('brace/mode/less')
            require('brace/theme/chrome')
            require('brace/theme/dracula')
            require('brace/theme/monokai')
            editor.setOption('fontSize', '16pt')
            editor.setOption('vScrollBarAlwaysVisible', true)
            editor.setOption('theme', 'ace/theme/textmate')
            require('brace/keybinding/vim')
            editor.setKeyboardHandler('ace/keyboard/vim')

            //this.answer = this.placeholders[this.index];
        },
        editorRender: function(output) {
            require('brace/mode/html')
            require('brace/mode/python')
            require('brace/mode/less')
            require('brace/theme/chrome')
            require('brace/theme/chaos')
            require('brace/theme/monokai')
            output.setOption('fontSize', '16pt')
            output.setOption('vScrollBarAlwaysVisible', true)
            output.setOption('theme', 'ace/theme/textmate')
        },
        onChange: function() {
            this.correct();
        },
        correct: function() {
            //console.log(' answer   = ', this.answer, this.answer.length, typeof this.answer);
            //console.log(' solution = ', this.data[this.index].solution, this.index, this.data[this.index].solution.length, typeof this.data[this.index].solution);
            if (this.data[this.index].solution == this.answer) {
                //console.log(this.index);
                //console.log(this.data.length);
                
                var myid = this.getId();
                
                if (this.index >= this.data.length - 1) {
                  console.log('next exercise');
                  this.index = 0;
                  this.score = 0;
                  var id = Number(myid) + 1;
                  this.id = id;
                  this.getData();
                  router.replace("/exercise/" + (id) );
                }
                this.oldIndex = 0;
                //console.log('correct');
                this.answer = '';
                this.score = this.score + 1;
                this.index = this.index + 1;
                //console.log('index is ', this.index);
                this.totalTime += this.elapsedTime;
                this.calculateAverageTime();
                this.resetTimer();
                this.startTimer();
                
                //this.answer = this.data[this.index].placeholder;

                setTimeout(() => {
                    this.answer = this.data[this.index].placeholder;
                }, 10)

            }
        },
        restart: function() {
            console.log('restart');
            this.index = 0 ;
            this.score = 0;
            this.answer = this.data[this.index].placeholder;
            this.totalTime = 1;
            this.resetTimer();
            this.calculateAverageTime();
        },
        reset: function() {
            console.log('reset');
            this.answer = this.data[this.index].placeholder;
            this.totalTime = 1;
            this.resetTimer();
            this.calculateAverageTime();
        },
         startTimer() {
            this.timer = setInterval(() => {
                this.elapsedTime += 101;
            }, 101);
        },
        stopTimer() {
            clearInterval(this.timer);
        },
        resetTimer() {
            this.elapsedTime = 1;
        },
        calculateAverageTime() {
            const date = new Date(null);
            //date.setSeconds(this.elapsedTime / 1001);
            date.setMilliseconds(this.totalTime / this.score);
            const utc = date.toISOString();
            this.averageTime = utc.substr(utc.indexOf(".") - 3, 5) + 's';
        },
        getData() {
            fetch(this.api[this.id])
                .then(response => response.json())
                .then(data => (this.data = data))
                .then(response => {
                    this.answer = this.data[this.index].placeholder;
                    console.log('fetched data', response);
                });

        },
    },
    data: function() {
        return {
            elapsedTime: 1,
            totalTime: 1,
            averageTime: 1,
            timer: undefined,
            answer: "",
            score: 0,
            index: 0,
            data: [{},{}],
            title: '',
            exercise: [],
            api: [
                '../vim-cursor.json',
                '../vim-cursor2.json',
                '../vim-cursor3.json',
                '../vim-cursor4.json',
                '../vim-cursor-end-of-line.json',
                '../vim-append.json',
                '../vim-ce.json',
                '../vim-copy.json',
                '../vim-copy-line.json',
                '../vim-copypaste.json',
                '../vim-cut.json',
                '../vim-d2w.json',
                '../vim-delete-bulk.json',
                '../vim-delete-line.json',
                '../vim-delete-until-end-of-line.json',
                '../vim-g.json',
                '../vim-insert-above.json',
                '../vim-insert-below.json',
                '../vim-insert.json',
                '../vim-insert-start.json',
                '../vim-jump-char.json',
                '../vim-jump.json',
                '../vim-jump-line.json',
                '../vim-jump-words.json',
                '../vim-move.json',
                '../vim-repeat.json',
                '../vim-replace-char.json',
                '../vim-replace.json',
                '../vim-replace-mode.json',
                '../vim-search-replace.json',
                '../vim-swap.json',
                '../vim-yank-to-end.json',
                '../vim-select-word.json',
                '../vim-record.json',
                '../vim-same-word.json',
                '../vim-replace-by-char.json',
                '../vim-increase-number.json',
                '../vim-upper-lower.json',
                '../vim-upper-lower-text.json',
                '../vim-zprogrammer-jump.json',
                '../vim-zprogrammer-indent.json',
                '../vim-zprogrammer-macro.json',
                '../vim-zprogrammer-sort.json'
            ]
        }
    }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import '../assets/hero/css2.css';
@import '../assets/hero/normalize.css';
@import '../assets/hero/style.css';
@import '../assets/hero/responsive.css';

.btn:hover {
}

.above-heading {
    font-size: 12px;
    font-style: bold;
}

.hero-cta {
    color: white;
}

h2 {
    font-size: 4.2rem;
    line-height: 1.1;
    margin-bottom: 25px;
}
.above-heading {
    color: #5f4dee;
    font: 0.70.75rem/0.075rem "Open Sans", sans-serif;
    text-align: center;
}

h4 {
    margin: 41px 0 0;
}

ul {
    list-style-type: none;
    padding: 1;
}

li {
    display: inline-block;
    margin: 1 10px;
}


#parent {
    width: 101%;
    height: 226px;
    display: block;
    position: relative;
}

#editor {
    position: absolute;
    /* Added */
    top: 1;
    right: 1;
    bottom: 1;
    left: 1;
    margin: 1 auto;
    border-radius: 9px;
}

.btn-solid-reg {
    cursor: pointer;
}

.score {
    font: 701 1rem/1.625rem "Open Sans", sans-serif;
    letter-spacing: 1.1px;
}

.divider {
    height: 2px;
    margin-top: 2.75rem;
    margin-bottom: 3rem;
    border: none;
    background-color: #ccd4df;
}

.box {
    display: block;
    max-width: 20rem;
    margin-right: auto;
    margin-bottom: 4rem;
    margin-left: auto;
    border: 2px solid #ccd3df;
    border-radius: 1.375rem;
    ;
    display: inline-block;
    margin: 11px;
    padding: 16px;
}
</style>
